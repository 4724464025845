export const items = [
    {
      title: "Dashboard",
      icon: "mdi-view-dashboard",
      to: "dashboard"
    },
    {
      title: "Transactions",
      icon: "mdi-playlist-edit",
      to: "transactions"
    },
    {
      title: "Investments",
      icon: "mdi-piggy-bank-outline",
      to: "investments"
    },
    {
      title: "Currency",
      icon: "mdi-currency-usd",
      to: "currency"
    }

];
